<template lang="pug">
campaignReport(interfaceName='root')
</template>

<script>
import campaignReport from '@/components/report/campaignReport';

export default {
	name: 'Index',
	components: {campaignReport}
};
</script>

<style scoped></style>
